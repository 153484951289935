<template>
  <div class="pointer-events-none">
    <div v-for="i in 300" :key="i" :style="getStyle()" aria-hidden="true" />
  </div>
</template>

<script lang="ts" setup>
import { useTimeoutFn } from '@vueuse/core'

function getStyle() {
  const left = Math.random() * 100
  const delay = Math.random() * 10000
  const leftPercentageMin = -50
  const leftPercentageMax = 50
  const red = Math.round(Math.random() * 255)
  const green = Math.round(Math.random() * 255)
  const blue = Math.round(Math.random() * 255)
  const alpha = Math.round(Math.random() * 255)
  const rotateStart = Math.round(Math.random() * 360).toString() + 'deg'
  const rotateEnd = Math.round(Math.random() * 360).toString() + 'deg'
  const leftStart =
    Math.floor(
      Math.random() * (leftPercentageMax - leftPercentageMin + 1) +
        leftPercentageMin,
    ).toString() + 'px'
  const leftEnd =
    Math.floor(
      Math.random() * (leftPercentageMax - leftPercentageMin + 1) +
        leftPercentageMin,
    ).toString() + 'px'

  return `
    width: 5px;
    height: 10px;
    top: 0;
    left: calc(${left}vw - 10px);
    position: fixed;
    z-index: 999999;
    opacity: 0;
    background-color: rgba(${red}, ${green}, ${blue}, ${alpha});
    --left-start: ${leftStart};
    --left-end: ${leftEnd};
    --rotate-start: ${rotateStart};
    --rotate-end: ${rotateEnd};
    animation: raeppli-drop linear 3s;
    animation-delay: ${delay}ms;
  `
}

const emit = defineEmits(['done'])

useTimeoutFn(() => {
  emit('done')
}, 13000)
</script>

<style lang="postcss">
@keyframes raeppli-drop {
  0% {
    opacity: 0;
    top: -3%;
  }
  0.01% {
    opacity: 1;
    top: -3%;
    transform: translateX(var(--left-start)) rotate(var(--rotate-start));
  }
  99.999% {
    top: calc(100% - 10px);
    opacity: 1;
    transform: translateX(var(--left-end)) rotate(var(--rotate-end));
  }
  100% {
    top: calc(100% - 10px);
    opacity: 0;
    transform: translateX(var(--left-end)) rotate(var(--rotate-end));
  }
}
</style>
